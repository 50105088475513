import { RouteProps } from 'react-router-dom';
import { MarketplacesPath, marketplacesRoutes } from 'shared/config/routeConfig/marketplacesRouteConfig';
import { DashboardPage } from 'pages/Marketplaces/DashboardPage';
import { OrdersPage } from 'pages/Marketplaces/OrdersPage';
import { ProductsPage } from 'pages/Marketplaces/ProductsPage';
import { ReturnsPage } from 'pages/Marketplaces/ReturnsPage';
import { OrdersManagePage } from 'pages/Marketplaces/OrdersManagePage';

export const marketplacesRoutesConfig: Record<MarketplacesPath, RouteProps> = {
  [MarketplacesPath.DASHBOARD]: {
    path: marketplacesRoutes[MarketplacesPath.DASHBOARD],
    element: <DashboardPage />,
  },
  [MarketplacesPath.PRODUCTS]: {
    path: marketplacesRoutes[MarketplacesPath.PRODUCTS],
    element: <ProductsPage />,
  },
  [MarketplacesPath.ORDERS]: {
    path: marketplacesRoutes[MarketplacesPath.ORDERS],
    element: <OrdersPage />,
  },
  [MarketplacesPath.RETURNS]: {
    path: marketplacesRoutes[MarketplacesPath.RETURNS],
    element: <ReturnsPage />,
  },
  [MarketplacesPath.ORDERS_MANAGE]: {
    path: marketplacesRoutes[MarketplacesPath.ORDERS_MANAGE],
    element: <OrdersManagePage />,
  },
};
