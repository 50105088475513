import { RouteProps } from 'react-router-dom';
import { marketplacesRoutesConfig } from 'app/providers/AppRouter/config/marketplacesRoutes';
import { settingsRoutesConfig } from 'app/providers/AppRouter/config/settingsRoutes';
import { loyaltyRoutesConfig } from 'app/providers/AppRouter/config/loyaltyRoutes';
import { ozonRoutesConfig } from 'app/providers/AppRouter/config/ozonRoutes';

export const appRoutes: RouteProps[] = [];
appRoutes.push(...Object.values(marketplacesRoutesConfig));
appRoutes.push(...Object.values(settingsRoutesConfig));
appRoutes.push(...Object.values(loyaltyRoutesConfig));
appRoutes.push(...Object.values(ozonRoutesConfig));
