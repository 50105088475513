import { MainRoutes } from 'shared/config/routeConfig/index';

export enum MarketplacesPath {
    DASHBOARD = 'dashboard',
    PRODUCTS = 'products',
    ORDERS = 'orders',
    RETURNS = 'returns',
    ORDERS_MANAGE = 'ordersManage',
}

export const marketplacesRoutes: Record<MarketplacesPath, string> = {
  [MarketplacesPath.DASHBOARD]: MainRoutes.MARKETPLACES + MarketplacesPath.DASHBOARD,
  [MarketplacesPath.PRODUCTS]: MainRoutes.MARKETPLACES + MarketplacesPath.PRODUCTS,
  [MarketplacesPath.ORDERS]: MainRoutes.MARKETPLACES + MarketplacesPath.ORDERS,
  [MarketplacesPath.RETURNS]: MainRoutes.MARKETPLACES + MarketplacesPath.RETURNS,
  [MarketplacesPath.ORDERS_MANAGE]: MainRoutes.MARKETPLACES + MarketplacesPath.ORDERS_MANAGE,
};
